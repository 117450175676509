/**
 * EpiCurrents Viewer Neonatal Seizure dataset loader.
 * https://zenodo.org/record/4940267
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import { GenericStudyLoader } from './GenericStudyLoader';
import SETTINGS from "CONFIG/Settings";
import { secondsToTimeString } from 'LIB/util/conversions';
export default class NeonatalSeizureDatasetLoader extends GenericStudyLoader {
    async loadDataset(dir, callback, config = {}) {
        // Load the dataset as a filesystem item
        const studies = [];
        // Populate annotations
        let annotationsA = new Map();
        let annotationsB = new Map();
        let annotationsC = new Map();
        const constructAnnotations = async (fileUrl, annotator) => {
            const fileResponse = await fetch(fileUrl);
            if (!fileResponse.ok) {
                return;
            }
            const annotations = annotator === 'A'
                ? annotationsA
                : annotator === 'B'
                    ? annotationsB
                    : annotationsC;
            const fileData = await fileResponse.text();
            const fileRows = fileData.split(/\r?\n/g);
            const curAnnos = new Map();
            for (let i = 0; i < fileRows.length; i++) {
                const row = fileRows[i];
                if (!i) {
                    if (row !== '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79') {
                        return;
                    }
                    else {
                        continue;
                    }
                }
                const curSeizures = row.split(',');
                for (let j = 0; j < curSeizures.length; j++) {
                    const idx = (j + 1).toString();
                    const annotation = curAnnos.get(idx);
                    if (curSeizures[j] === '1') {
                        // Check if there is an annotation for the seizure and create one if not
                        if (!annotation) {
                            curAnnos.set(idx, {
                                annotator: null,
                                channels: [],
                                duration: 0,
                                id: `evt_seizure_${annotator}`,
                                label: `Seizure (${annotator})`,
                                priority: 1,
                                start: i - 1,
                                text: `Seizure evaluated by annotator ${annotator}.`,
                                type: "event"
                            });
                        }
                    }
                    else if (curSeizures[j] === '0' || !curSeizures[j].length) {
                        if (annotation) {
                            // End the annotation here
                            annotation.duration = i - annotation.start - 1;
                            annotation.text = `Seizure evaluated by annotator ${annotator}.\nDuration: ${secondsToTimeString(annotation.duration)}.`;
                            const finalAnnos = annotations.get(idx) || [];
                            finalAnnos.push(annotation);
                            annotations.set(idx, finalAnnos);
                            curAnnos.delete(idx);
                        }
                    }
                }
            }
        };
        for (const file of dir.files) {
            if (!file.file && !file.url) {
                continue;
            }
            if (file.name === 'annotations_2017_A_fixed.csv' || file.name === 'annotations_2017_A.csv') {
                await constructAnnotations(file.file ? URL.createObjectURL(file.file) : file.url, 'A');
            }
            else if (file.name === 'annotations_2017_B.csv') {
                await constructAnnotations(file.file ? URL.createObjectURL(file.file) : file.url, 'B');
            }
            else if (file.name === 'annotations_2017_C.csv') {
                await constructAnnotations(file.file ? URL.createObjectURL(file.file) : file.url, 'C');
            }
        }
        // Add extra colors to EEG annotation styles
        SETTINGS.eeg.annotations.idColors['evt_seizure_B'] = [0.2, 0.6, 0.2, 0.75];
        SETTINGS.eeg.annotations.idColors['evt_seizure_C'] = [0.6, 0.2, 0.2, 0.75];
        // Sort the files according to EEG file number
        dir.files.sort((a, b) => parseInt((a.name.match(/^eeg(\d+)\.edf$/) || ['', '0'])[1]) - parseInt((b.name.match(/^eeg(\d+)\.edf$/) || ['', '0'])[1]));
        // Construct studies and add annotations
        for (const file of dir.files) {
            if (!file.file && !file.url) {
                continue;
            }
            const filenameParts = file.name.match(/^eeg(\d+)\.edf$/);
            if (filenameParts) {
                const fileIdx = filenameParts[1];
                const study = await this.loadFromFileUrl(file.file ? URL.createObjectURL(file.file) : file.url, { name: 'EEG ' + filenameParts[1].padStart(2, '0') + '.edf' });
                study.meta.annotations = [];
                const annosA = annotationsA.get(fileIdx);
                if (annosA) {
                    study.meta.annotations.push(...annosA);
                }
                const annosB = annotationsB.get(fileIdx);
                if (annosB) {
                    study.meta.annotations.push(...annosB);
                }
                const annosC = annotationsC.get(fileIdx);
                if (annosC) {
                    study.meta.annotations.push(...annosC);
                }
                // Return the study object via callback
                await callback(study);
            }
            else if (file.name.match(/article\.pdf/i)) {
                const study = await this.loadFromFileUrl(file.file ? URL.createObjectURL(file.file) : file.url, { name: "Article.pdf" });
                // Return the study object via callback
                await callback(study);
            }
            else if (file.name.match(/.+\.md/i)) {
                const study = await this.loadFromFileUrl(file.file ? URL.createObjectURL(file.file) : file.url, { name: file.name });
                // Return the study object via callback
                await callback(study);
            }
        }
    }
}
