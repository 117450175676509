/**
 * EpiCurrents Viewer Generic ONNX loader.
 * @package    epicurrents-viewer
 * @copyright  2023 Sampsa Lohi
 * @license    MIT
 */
import Log from 'scoped-ts-log';
import GenericLoader from './GenericLoader';
const SCOPE = 'GenericOnnxLoader';
export class GenericOnnxLoader extends GenericLoader {
    _activeModel = null;
    _availableModels = new Map();
    _modelLoading = false;
    _progress = {
        complete: 0,
        startIndex: 0,
        target: 0,
    };
    _runInProgress = false;
    _source = null;
    constructor(model) {
        super(SCOPE);
        if (model) {
            this.loadModel(model);
        }
    }
    get activeModel() {
        return this._activeModel;
    }
    set activeModel(value) {
        this._activeModel = value;
        this.onPropertyUpdate('active-model', value);
    }
    /**
     * A Map containing properties of the models available in this loader.
     * * Full name of the model.
     * * A list of study types supported by the model.
     */
    get availableModels() {
        const models = new Map();
        for (const [key, value] of this._availableModels) {
            models.set(key, {
                name: value.name,
                supportsStudy: (study) => value.supportedStudyTypes.includes(`${study.context}:${study.type}`),
            });
        }
        return models;
    }
    get isReady() {
        return super.isReady
            && (this._source !== null)
            && (this._activeModel !== null)
            && !this._modelLoading;
    }
    get modelLoading() {
        return this._modelLoading;
    }
    set modelLoading(value) {
        this._modelLoading = value;
        this.onPropertyUpdate('model-loading', value);
    }
    get runProgress() {
        return this._progress.target ? this._progress.complete / this._progress.target : 0;
    }
    get runInProgress() {
        return this._runInProgress;
    }
    set runInProgress(value) {
        this._runInProgress = value;
        this.onPropertyUpdate('run-in-progress', value);
    }
    /**
     * Load the given model into a web worker.
     * @param model - Name of the model (case-insensitive) or null.
     */
    async loadModel(model) {
        if (model === this._activeModel) {
            return;
        }
        if (!this._source) {
            Log.error(`Cannot load model without an active data source.`, SCOPE);
            return;
        }
        if (this._source) {
            this.resetProgress();
        }
        if (model === null) {
            this.activeModel = null;
            this.onPropertyUpdate('is-ready');
            return;
        }
        const modelProps = this._availableModels.get(model.toLowerCase());
        if (modelProps) {
            // Reject source if it is not of supported type
            if (!modelProps.supportedStudyTypes.includes(`${this._source.context}:${this._source.type}`)) {
                Log.warn(`Resource ${this._source.name} type ${this._source.context}:${this._source.type} ` +
                    `is not supported by ONNX model ${modelProps.name} (` +
                    modelProps.supportedStudyTypes.length ? `supported types are ${modelProps.supportedStudyTypes.join(', ')}`
                    : `loader has no supperted types set` +
                        `).`, this._scope);
                return;
            }
            this.modelLoading = true;
            try {
                if (this._worker) {
                    this._worker.removeEventListener('message', this.workerMessage);
                }
                this.setupWorker(modelProps.worker);
                this._worker?.addEventListener('message', this.workerMessage.bind(this));
                await this.prepare();
                Log.debug(`Loaded ONNX model ${model}.`, SCOPE);
                this.activeModel = model;
                return true;
            }
            catch (error) {
                Log.error(`Creating a web worker for ONNX model ${model} failed.`, SCOPE, error);
                return false;
            }
            finally {
                this.modelLoading = false;
                this.onPropertyUpdate('is-ready');
            }
        }
        Log.error(`The given ONNX model ${model} was not found in available models (${Array.from(this._availableModels.keys()).join(',')}).`, SCOPE);
        return false;
    }
    pauseRun() {
        this.runInProgress = false;
    }
    async prepare() {
        const path = window.location.pathname;
        const dir = path.substring(0, path.lastIndexOf('/')) + '/onnx';
        const callbacks = {
            resolve: () => { },
            reject: (reason) => { }
        };
        const commission = this._commissionWorker('prepare', callbacks, new Map([
            ['path', dir], // WebWorker doesn't know HTML file path
        ]));
        return commission.promise;
    }
    /**
     * Reset all progress-related parameters, including the target.
     * Any active runs will be stopped.
     */
    resetProgress() {
        if (this._runInProgress) {
            this.pauseRun();
        }
        this._progress.complete = 0;
        this._progress.startIndex = 0;
        this._progress.target = 0;
        for (const upd of this._actionWatchers) {
            // Inform all progress action watchers of the reset
            if (upd.actions.includes('run')) {
                upd.handler({ ...this._progress, action: 'run', update: 'progress' });
            }
        }
        this.onPropertyUpdate('progress');
    }
    /**
     * Run the active model.
     * **NOTE!** This is just a template method and must be overridden
     * in the child class.
     */
    async run() {
        // This method must be overridden in the child class!
    }
    setProgressTarget(target) {
        this._progress.target = target;
        this.onPropertyUpdate('progress');
    }
    /**
     * Set a new resource as the data source for this loader.
     * @param resource - The new data source.
     * @param childScope - Optional scope if this method is called from a child class.
     * @returns true if successful, false otherwise.
     */
    setSourceResource(resource, childScope) {
        Log.info(resource ? `Settings data source to ${resource.name}.`
            : `Clearing source resource.`, childScope || SCOPE);
        if (this._source) {
            this.resetProgress();
            this._source.removeAllPropertyUpdateHandlersFor(childScope || SCOPE);
        }
        if (this._runInProgress) {
            Log.debug(`Aborting run; new resource set.`, childScope || SCOPE);
            this.runInProgress = false;
            const callbacks = {
                resolve: () => { },
                reject: () => { }
            };
            this._commissionWorker('abort', callbacks);
        }
        this._source = resource;
        this.onPropertyUpdate('is-ready');
        return true;
    }
    /**
     * Handle messages from the worker.
     * @param message message from the web worker
     */
    workerMessage(message) {
        const data = message.data;
        if (!data) {
            return;
        }
        if (this._handleUnload(message) || this._handleLogEvent(message)) {
            // Message was handled by the parent
            return;
        }
        if (data.action === 'progress') {
            // Progress is a special update that is sent to 'run' action watchers
            this._progress.complete = this._progress.startIndex + data.complete;
            this.onPropertyUpdate('progress');
            return;
        }
        else {
            for (const upd of this._actionWatchers) {
                if (upd.actions.includes(data.action)) {
                    upd.handler({ ...data, action: data.action });
                }
            }
            if (data.action === 'prepare') {
                this._name = data.name;
                this.onPropertyUpdate('name');
            }
        }
        this._handleMessage(message);
    }
}
