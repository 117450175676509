/**
 * EpiCurrents Viewer main script.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import { createApp } from 'vue';
import { init as initI18n, availableLocales, T } from './i18n';
import AppStore from 'STORE';
// FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons/faAnglesLeft';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons/faAnglesRight';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
//import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faFastBackward } from '@fortawesome/free-solid-svg-icons/faFastBackward';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons/faFilePdf';
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faStepBackward } from '@fortawesome/free-solid-svg-icons/faStepBackward';
import { faStepForward } from '@fortawesome/free-solid-svg-icons/faStepForward';
//import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird'
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons/faWaveSquare';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import Log from 'scoped-ts-log';
import { GenericStudyLoader } from 'LIB/loaders/GenericStudyLoader';
import { urlsToFsItem } from 'LIB/util/conversions';
import NeonatalSeizureDatasetLoader from 'LIB/loaders/NeonatalSeizureDatasetLoader';
import { EdfFileLoader, MarkdownFileLoader, PdfFileLoader } from './file-loaders';
import { sleep } from 'LIB/util/general';
import SETTINGS from 'CONFIG/Settings';
import LoaderMemoryManager from 'LIB/loaders/LoaderMemoryManager';
import { SezNetOnnxLoader } from 'LIB/loaders/SezNetOnnxLoader';
import MixedMediaDataset from 'LIB/datasets/MixedMediaDataset';
library.add(faAngleDown);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faAngleUp);
library.add(faAnglesLeft);
library.add(faAnglesRight);
library.add(faBars);
library.add(faCircleInfo);
library.add(faCircleNotch);
library.add(faClock);
library.add(faEllipsisH);
library.add(faFastBackward);
library.add(faFilePdf);
//library.add(faSpinnerThird)
library.add(faListUl);
library.add(faPlay);
library.add(faPause);
library.add(faStepBackward);
library.add(faStepForward);
library.add(faTv);
library.add(faWaveSquare);
library.add(faXmark);
const SCOPE = 'index';
let INSTANCE_NUM = 1;
export class EpiCurrentsViewer {
    // Properties
    app = null;
    i18n = null;
    loaderManager = null;
    store = null;
    studyLoader = new GenericStudyLoader();
    constructor(logLevel) {
        if (logLevel) {
            Log.setPrintThreshold(logLevel);
        }
    }
    /**
     * Modify the default configuration before the app is launched.
     * After launching the app, use setSettingsValue() instead.
     * @param config - Field and value pairs to modify.
     * @example
     * EpiCurrentsViewer.configure(
     *  { 'modules.MNE': false }
     * )
     */
    configure = (config) => {
        if (this.app) {
            Log.warn(`Cannot alter default configuration after app launch. Use the setSettingsValue method instead.`, SCOPE);
            return;
        }
        field_loop: for (const [field, value] of Object.entries(config)) {
            Log.debug(`Modifying default configuration field '${field}' to value ${value.toString()}`, SCOPE);
            // Traverse field's "path" to target property
            const fPath = field.split('.');
            let cfgField = [SETTINGS];
            let i = 0;
            for (const f of fPath) {
                if (cfgField[i][f] === undefined) {
                    Log.warn(`Default configuration field '${field}' is invalid: cannot find property '${fPath.slice(i).join('.')}'. Valid properties are '${Object.keys(cfgField[i]).join("', '")}'.`, SCOPE);
                    continue field_loop;
                }
                if (i === fPath.length - 1) {
                    // Final field
                    const prop = cfgField.pop();
                    // Typecheck
                    if (prop[f].constructor === value.constructor) {
                        prop[f] = value;
                    }
                    else {
                        Log.warn(`Config property '${field}' constructor (${prop[f].constructor}) did not match given constructor (${value.constructor}).`, SCOPE);
                    }
                    continue field_loop;
                }
                else {
                    cfgField.push(cfgField[i][f]);
                }
                i++;
            }
        }
    };
    async createDataset(name) {
        if (!this.store) {
            Log.error(`Cannot add a dataset before the store has been initialized.`, SCOPE);
            return null;
        }
        const setName = name || T(`Dataset {n}`, null, { n: this.store.state.APP.datasets.length + 1 });
        const newSet = new MixedMediaDataset(setName);
        this.store.dispatch('add-dataset', newSet);
        return newSet;
    }
    displayUI() {
        const appCnt = document.querySelector(`#epicv${this.store?.state.containerId}`) || null;
        const plhCnt = document.querySelector(`#epicv${this.store?.state.containerId}-placeholder`) || null;
        if (!appCnt) {
            Log.error(`Container with the id ${this.store?.state.containerId} was not found, UI could not be displayed.`, 'index');
        }
        else {
            if (plhCnt) {
                plhCnt.style.display = 'none';
            }
            appCnt.style.display = 'block';
        }
    }
    /**
     * Update Vuex store fullscren property when document fullscreen state changes.
     * @returns void
     */
    fullscreenChange = () => {
        if (!this.store) {
            return;
        }
        const appCont = document.querySelector(`#epicv${this.store?.state.containerId}`);
        if (document.fullscreenElement === appCont) {
            this.store.commit('set-fullscreen', true);
        }
        else {
            this.store.commit('set-fullscreen', false);
        }
    };
    /**
     * Launch a viewer app in the given container div.
     * @param app - root app component
     * @param containerId id of the container div element
     * @param appId optional id for the app
     * @param locale optional primary locale code string
     * @return true if successful, false if not
     */
    launch = async (app, containerId = '', appId = `app${INSTANCE_NUM++}`, locale = 'en') => {
        // Make sure that the container element exists.
        // Prepend a hyphed to the container id, otherwise just use 'epicv'.
        // Using the literal 'epicv' in the selector is to avoid invalid selector errors.
        containerId = containerId.length ? `-${containerId}` : '';
        const appCnt = document.querySelector(`#epicv${containerId}`);
        if (!appCnt) {
            Log.error(`Container with the id ${containerId} was not found, viewer cannot be launched!`, 'index');
            return false;
        }
        const shadow = appCnt.attachShadow({ mode: 'open' });
        const root = document.createElement('div');
        shadow.appendChild(root);
        // Make sure app container and root take up all the available space
        appCnt.style.width = "100%";
        appCnt.style.height = "100%";
        root.style.width = "100%";
        root.style.height = "100%";
        this.app = createApp(app);
        this.app.provide('$epicv', this);
        // Initialize i18n
        if (availableLocales.indexOf(locale) === -1) {
            Log.warn(`Given locale ${locale} is not available, falling back to English!`, 'index');
            locale = 'en';
        }
        this.i18n = initI18n(this.app, locale);
        // Initialize Vuex store
        this.store = AppStore.init(this.app, { appId: appId, containerId: containerId });
        this.store.subscribe(mutation => {
            if (mutation.type === 'load-dataset-folder') {
                const dataset = mutation.payload.dataset;
                const promise = mutation.payload.promise;
                this.loadDataset(dataset.folder, 'local', dataset.name, dataset.context).then(() => {
                    promise.resolve();
                });
            }
            else if (mutation.type === 'load-study-folder') {
                const study = mutation.payload.study;
                const promise = mutation.payload.promise;
                this.loadStudyFiles(study.folder, study.scope, study.name, study.context).then(() => {
                    promise.resolve();
                });
            }
            else if (mutation.type === 'load-study-url') {
                const study = mutation.payload.study;
                const promise = mutation.payload.promise;
                this.loadStudy(study.url, study.scope, study.name, study.context).then(result => {
                    if (result) {
                        promise.resolve(result);
                    }
                    else {
                        promise.reject();
                    }
                });
            }
            //if (this.store && mutation.type === 'set-active-resource' && mutation.payload.type === 'eeg') {
            //for (let i=0; i<this.store.state.EEG.resources.length; i++) {
            //    const eeg = this.store.state.EEG.resources[i]
            //    if (eeg.id === mutation.payload.id && !eeg.isActive) {
            //        this.store.state.EEG.resources.splice(i, 1)
            //    }
            //}
            //}
        });
        // Listen to actions
        this.store.subscribeAction(action => {
            if (action.type === 'display-viewer') {
                this.displayUI();
            }
        });
        // Monitor full screen changes to keep store state up-to-date
        document.addEventListener('fullscreenchange', this.fullscreenChange, false);
        document.addEventListener('mozfullscreenchange', this.fullscreenChange, false);
        document.addEventListener('MSFullscreenChange', this.fullscreenChange, false);
        document.addEventListener('webkitfullscreenchange', this.fullscreenChange, false);
        // Initialize FontAwesome
        this.app.component('font-awesome-icon', FontAwesomeIcon);
        // Mount app
        const vm = this.app.mount(root);
        // Create a style tag for shadow root styles
        appCnt.shadowRoot?.querySelector('div')?.appendChild(document.createElement('style'));
        // @ts-ignore: Check for cross origin isolation; some features of the app don't work without it
        if (!window.crossOriginIsolated) {
            Log.warn(`Cross origin isolation is not enabled! Some features of the app are not available!`, 'index');
        }
        else {
            this.loaderManager = new LoaderMemoryManager(SETTINGS.app.maxLoadCacheSize);
        }
        return true;
    };
    /**
     * Load a dataset from the given `folder`.
     * @param folder - `FileSystemItem` containing the dataset files.
     * @param scope - Scope of the dataset resources (local or remote).
     * @param name - Optional name for the dataset.
     */
    loadDataset = async (folder, scope, name, context) => {
        // This is just a test implementation
        const datasetLoader = new NeonatalSeizureDatasetLoader();
        datasetLoader.registerFileLoader(new EdfFileLoader());
        datasetLoader.registerFileLoader(new MarkdownFileLoader());
        datasetLoader.registerFileLoader(new PdfFileLoader());
        const dataset = await this.createDataset(name);
        if (!dataset) {
            Log.error(`Failed to create a dataset for ${name || 'dataset loader'}.`, SCOPE);
            return;
        }
        else {
            this.store?.dispatch('set-active-dataset', dataset);
        }
        datasetLoader.loadDataset(Array.isArray(folder) ? urlsToFsItem(folder) : folder, async (study) => {
            if (!this.loaderManager) {
                Log.error(`Could not load study for a dataset, loader manager is not initialized.`, 'index');
                return;
            }
            if (study.type === 'doc:htm') {
                if (study.format === 'markdown') {
                    const MarkdownDocument = (await import(/* webpackChunkName: "document_markdown" */ 'LIB/document/MarkdownDocument')).default;
                    const doc = new MarkdownDocument(study.name, study.files[0].url);
                    this.store?.dispatch('add-resource', { resource: doc, scope: scope });
                }
            }
            else if (study.type === 'doc:pdf') {
                const PdfDocument = (await import(/* webpackChunkName: "document_pdf" */ 'LIB/document/PdfDocument')).default;
                const doc = new PdfDocument(study.name, study.files[0].url);
                this.store?.dispatch('add-resource', { resource: doc, scope: scope });
            }
            else if (study.type === 'sig:eeg') {
                const EegRecordingSAB = (await import(/* webpackChunkName: "biosignal_eeg" */ 'LIB/eeg/EegRecordingSAB')).default;
                const eeg = new EegRecordingSAB(study.name, study.meta.channels, study.meta.recording, this.loaderManager);
                //console.warn(study.meta.annotations)
                if (study.meta.annotations?.length) {
                    for (const annotation of study.meta.annotations) {
                        eeg.addAnnotation(annotation);
                    }
                }
                this.store?.dispatch('add-resource', { resource: eeg, scope: scope });
                eeg.loadStudy(study).then(() => {
                }).catch(e => {
                    console.error(e);
                });
            }
            // Datasets can be huge, let the UI refresh between items
            await Promise.all([sleep(10)]);
        });
        return dataset;
    };
    /**
     * Load a study from the given URL.
     * @param source - URL to study data file or loaded study object
     * @param name - Optional name for the study
     */
    loadStudy = async (source, scope, name, context) => {
        const study = typeof source === 'string'
            ? await this.studyLoader.loadFromFileUrl(source, { name: name })
            : Array.isArray(source) ? await this.studyLoader.loadFromDirectory(urlsToFsItem(source))
                : source;
        if (!study) {
            return;
        }
        if (!this.loaderManager) {
            Log.error(`Could not load study, loader manager is not initialized.`, 'index');
            return;
        }
        if (study.type === 'sig:eeg') {
            const EegRecordingSAB = (await import(/* webpackChunkName: "biosignal_eeg" */ 'LIB/eeg/EegRecordingSAB')).default;
            const eeg = new EegRecordingSAB(study.name, study.meta.channels, study.meta.recording, this.loaderManager);
            this.store?.dispatch('add-resource', { resource: eeg, scope: scope });
            eeg.loadStudy(study).then(() => {
                //console.log(eeg)
            }).catch(e => {
                console.error(e);
            });
            return eeg;
        }
        else if (study.type === 'sig:emg') {
            const EmgRecording = (await import(/* webpackChunkName: "biosignal_emg" */ 'LIB/emg/EmgRecording')).default;
            const emg = new EmgRecording(study.name);
            this.store?.dispatch('add-resource', { resource: emg, scope: scope });
            emg.loadStudy(study).then(() => {
                //console.log(emg)
            }).catch(e => {
                console.error(e);
            });
            return emg;
        }
        else if (study.type === 'sig:ncs') {
            const NcsRecording = (await import(/* webpackChunkName: "biosignal_ncs" */ 'LIB/ncs/NcsRecording')).default;
            const ncs = new NcsRecording(study.name);
            this.store?.dispatch('add-resource', { resource: ncs, scope: scope });
            ncs.loadStudy(study).then(() => {
                //console.log(ncs)
            }).catch(e => {
                console.error(e);
            });
            return ncs;
        }
        else if (study.type === 'sig:meg') {
            const MegRecording = (await import(/* webpackChunkName: "biosignal_meg" */ 'LIB/meg/MegRecording')).default;
            const meg = new MegRecording(study.name, study.meta.channels, study.meta.recording, this.loaderManager);
            this.store?.dispatch('add-resource', { resource: meg, scope: scope });
            meg.loadStudy(study).then(() => {
                //console.log(eeg)
            }).catch(e => {
                console.error(e);
            });
            return meg;
        }
        else if (study.type === 'doc:htm') {
            if (study.format === 'markdown') {
                const MarkdownDocument = (await import(/* webpackChunkName: "document_generic" */ 'LIB/document/MarkdownDocument')).default;
                const doc = new MarkdownDocument(study.name, study.files[0].url);
                this.store?.dispatch('add-resource', { resource: doc, scope: scope });
                return doc;
            }
        }
        else if (study.type === 'doc:pdf') {
            const PdfDocument = (await import(/* webpackChunkName: "document_generic" */ 'LIB/document/PdfDocument')).default;
            const doc = new PdfDocument(study.name, study.files[0].url);
            this.store?.dispatch('add-resource', { resource: doc, scope: scope });
            return doc;
        }
        return null;
    };
    /**
     * Load a study consisting of multiple files.
     * @param folder - `FileSystemItem` containing the study files.
     * @param name - Optional name for the study.
     */
    loadStudyFiles = async (folder, scope, name, context) => {
        const study = await this.studyLoader.loadFromDirectory(Array.isArray(folder) ? urlsToFsItem(folder) : folder);
        if (!study) {
            return;
        }
        if (!this.loaderManager) {
            Log.error(`Could not load study from files, loader manager is not initialized.`, 'index');
            return;
        }
        if (study.type === 'sig:eeg') {
            const EegRecordingSAB = (await import(/* webpackChunkName: "biosignal_eeg" */ 'LIB/eeg/EegRecordingSAB')).default;
            const eeg = new EegRecordingSAB(study.name, study.meta.channels, study.meta.recording, this.loaderManager);
            this.store?.dispatch('add-resource', { resource: eeg, scope: scope });
            eeg.loadStudy(study).then(() => {
            }).catch(e => {
                console.error(e);
            });
        }
        else if (study.type === 'sig:meg') {
            const EegRecordingSAB = (await import(/* webpackChunkName: "biosignal_eeg" */ 'LIB/eeg/EegRecordingSAB')).default;
            const eeg = new EegRecordingSAB(study.name, study.meta.channels, study.meta.recording, this.loaderManager);
            this.store?.dispatch('add-resource', { resource: eeg, scope: scope });
            eeg.loadStudy(study).then(() => {
            }).catch(e => {
                console.error(e);
            });
        }
    };
    /**
     * Open the provided resource.
     * @param resource - The resource to open.
     */
    openResource = (resource) => {
        this.store?.dispatch('set-active-resource', resource);
    };
    /**
     * Register a new file format loader to the study loader to enable parsing
     * files of the given format.
     * @param loader - `FileFormatLoader` to register.
     */
    registerFileLoader = (loader) => {
        this.studyLoader.registerFileLoader(loader);
    };
    setActiveDataset = (dataset) => {
        if (!this.store) {
            Log.error(`Cannot set active dataset before store has been initialized.`, SCOPE);
            return;
        }
        this.store.dispatch('set-active-dataset', dataset);
    };
    /**
     * Select the resource with the given `id` in current dataset as active.
     * @param id - Unique ID of the resource.
     */
    selectResource = (id) => {
        if (!this.store || !this.store.state.APP.activeDataset) {
            return;
        }
        const setResources = this.store.state.APP.activeDataset.resources;
        for (const resource of setResources) {
            if (resource.id === id && resource.isLoaded) {
                this.store.dispatch('set-app-scope', resource.type.split(':')[0]);
                this.store.dispatch('set-active-resource', resource);
            }
        }
    };
    setOnnxLoader = (loader) => {
        if (!this.store) {
            Log.error(`Cannot set ONNX loader before store has been initialized.`, SCOPE);
            return;
        }
        this.store.state.ONNX = loader || new SezNetOnnxLoader();
        this.store.state.SETTINGS.modules.ONNX = true; //loader ? true : false
    };
    /**
     * Set the given settings field to a new value. The field must already exist in settings,
     * this method will not create new fields.
     * @param field - Settings field to change (levels separated with dot).
     * @param value - New value for the field.
     * @example
     * ```
     * setSettingsValue('module.field.subfield', 'New Value')
     * ```
     */
    setSettingsValue = (field, value) => {
        this.store?.commit('set-settings-value', { field: field, value: value });
    };
}
// Set as a property of window
;
window.EpiCViewer = EpiCurrentsViewer;
