/**
 * EpiCurrents Viewer generic loader.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import GenericAsset from "LIB/common/GenericAsset";
import { NUMERIC_ERROR_VALUE } from "LIB/util/constants";
import { getOrSetValue, nullPromise } from "LIB/util/general";
import Log from "scoped-ts-log";
import SETTINGS from "CONFIG/Settings";
const SCOPE = 'GenericLoader';
export default class GenericLoader extends GenericAsset {
    _actionWatchers = [];
    /** On-going worker commissions waiting to be resolved. */
    _commissions = new Map();
    _manager;
    _memoryRange = null;
    _nextRequestNumber = 1;
    _scope;
    _worker = null;
    constructor(scope, worker, manager) {
        super(scope, GenericAsset.CONTEXTS.LOADER, '');
        this._scope = scope;
        this._manager = manager || null;
        if (worker) {
            this.setupWorker(worker);
        }
    }
    get bufferRangeStart() {
        if (!this._memoryRange) {
            return -1;
        }
        return this._memoryRange.start;
    }
    get memoryConsumption() {
        if (!this._memoryRange) {
            return 0;
        }
        return this._memoryRange.end - this._memoryRange.start;
    }
    get isReady() {
        return this._worker !== null;
    }
    get nextRequestNumber() {
        return this._nextRequestNumber++;
    }
    /**
     * Commission the worker to perform an action.
     * @param action - Name of the action to perform.
     * @param callbacks - Callbacks for resolving (and optionally rejecting) the action.
     * @param props - Additional properties to inject into the message (optional).
     * @param overwriteRequest - Overwrite any previous requests with the same action, discarding responses to any but the most recent request (default false).
     */
    _commissionWorker(action, callbacks, props, overwriteRequest = true) {
        if (!this._worker) {
            return {
                promise: nullPromise,
                reject: () => { },
                resolve: () => { },
                rn: NUMERIC_ERROR_VALUE
            };
        }
        const returnPromise = new Promise((resolve, reject) => {
            callbacks.resolve = resolve;
            if (callbacks.reject) {
                callbacks.reject = reject;
            }
        });
        const requestNum = this.nextRequestNumber;
        const msgData = {
            action: action,
            rn: requestNum
        };
        if (props) {
            for (const [key, value] of props) {
                msgData[key] = value;
            }
        }
        const commMap = getOrSetValue(this._commissions, action, new Map());
        if (overwriteRequest) {
            // Remove references to any previous requests
            commMap.clear();
        }
        commMap.set(requestNum, {
            rn: requestNum,
            reject: callbacks.reject,
            resolve: callbacks.resolve,
        });
        this._worker.postMessage(msgData);
        return {
            promise: returnPromise,
            reject: callbacks.reject,
            resolve: callbacks.resolve,
            rn: requestNum,
        };
    }
    /**
     * Get the awaiting commission matching the given worker message.
     * @param message - Message containing a possible commission.
     * @returns CommissionPromise or undefined if no commission found.
     */
    _getCommissionForMessage(message) {
        const commMap = this._commissions.get(message?.data?.action);
        if (commMap) {
            return commMap.get(message.data.rn) || commMap.get(0);
        }
        else {
            return undefined;
        }
    }
    /**
     * Check if the given message contains an instruction to add an
     * event to the log.
     * @param message - Message from the worker.
     * @param $this - A reference to this Loader.
     * @returns true if a log event was handled, false otherwise
     */
    _handleLogEvent(message) {
        const { action, event, extra, level, scope } = message.data;
        if (action === 'log' && event && level && scope) {
            Log.add(level, event, scope, extra);
            return true;
        }
        return false;
    }
    /**
     * Handle a generic message from the worker. Will check if a matching
     * commission can be found and either resolves or rejects it based on
     * the value of the success property in the message. This method expects
     * that:
     * - The message has an `action` property.
     * - The message has an `rn` (request number) property.
     * - A successful message has a `result` property.
     * - A non-successful message has a `reason` property.
     *
     * Optionally:
     * - The message has a `success` property.
     *
     * @param message - Message object from worker.
     * @returns true if resolved, false otherwise.
     */
    _handleMessage(message) {
        const commission = this._getCommissionForMessage(message);
        if (commission) {
            if (message.data.rn === commission.rn) {
                if (message.data.success === true) {
                    commission.resolve(message.data.result);
                    return true;
                }
                else if (message.data.success === false && commission.reject) {
                    commission.reject(message.data.reason);
                    return false;
                }
                else {
                    commission.resolve(); // Same as undefined result
                    return true;
                }
            }
            else {
                Log.debug(`Omitting response for action ${message.data.action} with an expired request number (expected ${commission.rn}, got ${message.data.rn}).`, SCOPE);
            }
        }
        return false;
    }
    _handleSettings(message) {
        const { action, fields } = message.data;
        if (action === 'update-settings' && fields?.length) {
            for (const field of fields) {
                // Watch changes in SETTINGS and relay changes to worker
                SETTINGS.addPropertyUpdateHandler(field, () => {
                    this._worker?.postMessage({
                        action: 'update-settings',
                        field: field,
                        value: SETTINGS.getFieldValue(field)
                    });
                }, this._scope);
            }
        }
    }
    /**
     * Check if the given message contains an instruction to unload the worker
     * and react accordingly.
     * @param message - Message from the worker.
     * @returns true if unloadingt was handled, false otherwise
     */
    _handleUnload(message) {
        const decommission = this._commissions.get('decommission');
        if (decommission &&
            (message.data.action === 'release-buffers' ||
                message.data.action === 'shutdown') &&
            message.data.success === true) {
            this._memoryRange = null;
            this.onPropertyUpdate('memory-consumption');
            decommission.get(0)?.resolve();
            if (message.data.action === 'shutdown') {
                this._worker?.terminate();
                this._worker = null;
                this.onPropertyUpdate('is-ready');
            }
            return true;
        }
        return false;
    }
    addActionWatcher(action, handler, caller) {
        for (const prev of this._actionWatchers) {
            if (prev.handler === handler) {
                if (!prev.actions.includes(action)) {
                    prev.actions.push(action);
                }
                return;
            }
        }
        this._actionWatchers.push({
            actions: [action],
            handler: handler,
            caller: caller,
        });
    }
    removeActionWatcher(handler) {
        for (let i = 0; i < this._actionWatchers.length; i++) {
            if (this._actionWatchers[i].handler === handler) {
                this._actionWatchers.splice(i, 1);
                return;
            }
        }
    }
    removeAllActionWatchersFor(caller) {
        for (let i = 0; i < this._actionWatchers.length; i++) {
            if (this._actionWatchers[i].caller &&
                this._actionWatchers[i].caller === caller) {
                this._actionWatchers.splice(i, 1);
                i--;
            }
        }
    }
    removeAllActionWatchers() {
        this._actionWatchers.splice(0);
    }
    async requestMemory(amount) {
        if (!this._manager) {
            Log.error(`Too early to request memory, manager is not set yet.`, SCOPE);
            return false;
        }
        Log.debug(`Requesting to allocate ${amount * 4} bytes of memory.`, SCOPE);
        this._memoryRange = await this._manager.allocate(amount, this);
        this.onPropertyUpdate('memory-consumption');
        return true;
    }
    setupWorker(worker) {
        this._worker = worker;
        this.onPropertyUpdate('is-ready');
    }
    shutdown() {
        SETTINGS.removeAllPropertyUpdateHandlersFor(this._scope);
        const callbacks = {
            reject: () => { },
            resolve: () => { },
        };
        const response = this._commissionWorker('shutdown', callbacks);
        // Shutdown doesn't need a request number
        const shutdown = getOrSetValue(this._commissions, 'shutdown', new Map());
        shutdown.set(0, response);
        return response.promise;
    }
    unload() {
        const callbacks = {
            reject: () => { },
            resolve: () => { },
        };
        const response = this._commissionWorker('release-buffers', callbacks);
        // Decommission doesn't need a request number
        const decommission = getOrSetValue(this._commissions, 'decommission', new Map());
        decommission.set(0, response);
        return response.promise;
    }
}
